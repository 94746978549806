import React from "react";
import "./WealthPass.css";

const WealthPass = () => {
  return (
    <div className="wealth__pass" id="WealthPass">
      <div className="section__head" data-aos="fade-up">
        <h3>WEALTH PASS</h3>
      </div>
      <div className="separate" data-aos="fade-up">
        <img src="/images/weath.svg" alt="wealth avatar" />
        <div>
          <h6>
            Wealth Pass With SACA Leverage By DASA. Wealth acceleration, in fast
            motion.
          </h6>
          <h5>What is Wealth PASS?</h5>
          <p>
            Wealth PASS is Wealth Propelled Accumulated Synthetic SLAPA
            <br />
            <br />
            It is the powerful combination of high returns from Synthetic Assets
            fuelled even further by the provision of high leveraged multiples
            for daily use from your SACA dashboard. The leverage you obtain is a
            multiple of between 10x to 100x wholly secured on your Collateral
            and a gross asset-share agreement. <br />
            <br /> Setup a SACA (Synthetic Assets Collateralised Account) with
            Ultainfinity, to experience a Wealth PASS.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WealthPass;
