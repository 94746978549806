import React, { useEffect } from "react";
import Footer from "../../components/Home/Footer/Footer";
import Navbar from "../../components/Home/Navbar/Index";
import "./Whitepaper.css";

const Whitepaper = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="whitepaper__section">
      <Navbar />
      <div className="hero__section">
        <img src="/images/whitepaper/white__logo.svg" alt="white logo" />
        <h3>Whitepapers</h3>
        <p>
          We are a platform dedicated to global crypto wealth creation through
          multiple specially created wealth-themed projects. Each whitepaper
          gives you the needed information about the concept, working model and
          potential benefits inherent in the project. Our projects are unique
          with distinctive peculiarities and thus necessitated the creation of
          an all-inclusive information-rich technical whitepaper for each
          project for the general public's consumption.
        </p>
      </div>

      <div className="main__section">
        {/* launchpad */}
        <div className="main__card launchpad">
          <div className="left">
            <h5>Ultainfinity Wealth LaunchPad</h5>
            <p>
              Our Wealth LaunchPad is the transitional platform for Ultainfinity
              ecosystem cryptocurrencies, PreSales and PreMarket exchange. A
              pillar of Ultainfinity ecosystem in furtherance of our Global
              wealth creation agenda.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/launchpad.svg" alt="launchpad" />
          </div>
        </div>

        {/* global */}
        <div className="main__card global__group">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>Ultainfinity Global Group</h5>
            <p>
              Our Umbrella platform sponsors multiple Ultainfinity ecosystem
              projects through the coalition of partners, namely: Ultainfinity
              Group, Slapa Group and Ultainfinity Communications Group. The
              Ultainfinity Global Group, also referred to as the "UGG", connects
              and serves as the reference point and pathfinder for all
              Ultainfinity ecosystem projects.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/global__group.svg" alt="launchpad" />
          </div>
        </div>

        {/* Utta token */}
        <div className="main__card utta__token">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>UTTA Token</h5>
            <p>
              The Utta token is one of Ultainfinity's pioneer cryptocurrencies
              set to change the way crypto wealth creation is viewed forever. A
              product of a unique model systems which contributes to its unique
              property of being able to grow exponentially up to 99,900% in just
              a few weeks of Launch. A wealth creating token living up to its
              mission.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/utta__token.svg" alt="launchpad" />
          </div>
        </div>

        {/* Divit token */}
        <div className="main__card divit__token">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>DIVIT Token</h5>
            <p>
              Ultainfinity's Unique, Ubiquitous Utility token with an Uncapped
              price growth, a pioneer token set to bridge the wealth gap in the
              world through its unique and never seen before offerings. A new
              world leader in the cryptocurrency industry set to revolutionize
              crypto wealth creation. Performing multiple roles in the
              Ultainfinity ecosystem places it a shoulder tall to other
              Ultainfinity cryptocurrencies.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/divit__token.svg" alt="launchpad" />
          </div>
        </div>

        {/* Ultainfinity wallet */}
        <div className="main__card ultainfinity__wealth">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>Ultainfinity Wallet</h5>
            <p>
              Our bank vault standard, unique and secure wallet is targeted at
              completing our fully functional ecosystem. Our Ultainfinity wallet
              houses Ultainfinity cryptocurrencies, Ultainfinity cards,
              Ultainfinity CRP, other supported top cryptocurrencies and many
              more.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/ultainfinity__wealth.svg" alt="launchpad" />
          </div>
        </div>

        {/* Ulainfinity Exchange */}
        <div className="main__card ultainfinity__exchange">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>Ultainfinity Exchange</h5>
            <p>
              Our exchange is an offshoot of the initial PreMarlet exchange but
              better. It is an embodiment of unique offerings like the DASA
              trader, which is an ever-present liquidity provider and standby
              cryptocurrency buyer for Ultainfinity traders. Our Unique exchange
              also supports the trade of borrowed cryptocurrencies through our
              Instant CreditLine.
            </p>
            <span>View Whitepaper</span>
          </div>
          <img
            src="/images/whitepaper/ultainfinity__exchange.svg"
            alt="ultainfinity__exchange"
            className="right"
          />
        </div>

        {/* Instant creditline */}
        <div className="main__card instant">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>Instant CreditLine</h5>
            <p>
              Our Ultainfinity Instant CreditLine is the ultimate panacea to the
              problem of lack of financial power to invest in the multiple
              Ultainfinity wealth creation projects. Characterized by its ease
              of accessing loans, outsized benefits and high CreditLine places
              our Instant CreditLine project as the ultimate aid towards
              ensuring a huge number of investors can create immense wealth
              globally.
            </p>
            <span>View Whitepaper</span>
          </div>
          <img src="/images/whitepaper/instant.svg" alt="ultainfinity__exchange" className="right" />
        </div>

        {/* One step ID */}
        <div className="main__card one__stepID">
          <div className="left">
            <h5 style={{ marginBottom: "-40px" }}>OneStep ID</h5>
            <p>
              Our OneStep ID is a unique identification system native to the
              Ultainfinity ecosystem that can be likened to the Apple ID but
              better. The OneStep ID defeats the need for the conventional
              signup and sign-in methods. A novel invention set to revolutionize
              the way Identification works in the cryptocurrency industry, most
              especially platforms with diverse projects with the need to
              enhance interoperability between various projects in the
              ecosystem.
            </p>
            <span>View Whitepaper</span>
          </div>
          <div className="right">
            <img src="/images/whitepaper/one__stepID.svg" alt="launchpad" />
          </div>
        </div>
      </div>

      <div className="group__image">
        <img src="/images/whitepaper/group.svg" alt="group avatar" />
      </div>
      <Footer />
    </div>
  );
};

export default Whitepaper;
