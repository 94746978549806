import React from "react";
import "./roadmap__styles.css";

const DevelopmentStages = () => {
  return (
    <div className="development__stages">
      <div className="development__stage one">
        <div className="light">
          <div>
            <img src="/images/roadmap/light.svg" alt="light avatar" />
            <p>Development Stage (4th Quarter 2020)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Development Stage (4th Quarter 2020)</h3>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Idea Conceptualization</h5>
            </div>
            <p>
              This is one of several Ultainfinity Global Group (UGG)wealth
              creation tools. However Ultainfinity Wealth LaunchPad is conceived
              in order to house the debutant tokens that would launch Users into
              wealth. It is conceived to also house all other supporting tools
              that would pioneer the successful launch of Ultainfinity tokens.
              The Ultainfinity Wealth LaunchPad is a permanent project of the
              Ultainfinity Global Group which would be pivotal to housing
              Ultainfinity tokens.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Identifying the Wealth LaunchPad working Concept</h5>
            </div>
            <p>
              Built to engender working parts to ensure hitch free navigation
              and amazing user experience.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Team Assembly</h5>
            </div>
            <p>
              Experts were brought in to critically analyse and investigate the
              feasibility of the LaunchPad
            </p>
          </div>
        </div>
      </div>

      <div className="development__image">
        <img
          src="/images/roadmap/lineone.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage reverse__stage two">
        <div className="light">
          <div>
            <img src="/images/roadmap/light.svg" alt="light avatar" />
            <p>Development Phase Stage II (1st and 2nd Quarter of 2021)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Development Phase Stage II (1st and 2nd Quarter of 2021)</h3>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Identification and Selection of working parts</h5>
            </div>
            <p>
              This was a stage where all Ultainfintiy Wealth LaunchPad elements
              were identified. This includes tokens, whitelist, PreSale,
              Premarket exchange among others
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Whitelist</h5>
            </div>
            <p>
              This is the first tool placed conceived to be placed on the
              Ultainfnity Wealth LaunchPad. The registration of Users on the
              whitelist is the first procedure to participating in the
              Ultainfnity wealth projects.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>PreSale</h5>
            </div>
            <p>
              The Ultainifinity Wealth LaunchPad houses the first PreSale which
              would be conducted for successful users on the whitelist. This is
              when Users will get the Ultainfintiy tokens for the lowest price
              ever.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Premarket</h5>
            </div>
            <p>
              The Ultainfinity Wealth LaunchPad is to house the first Premarket
              exchange open to successful participants in the PreSale.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Tokens</h5>
            </div>
            <p>
              The Wealth LaunchPad is to house the very first set of
              Ultainfinity tokens which includes the Utta token, Divit token
              among others.
            </p>
          </div>
        </div>
      </div>

      <div className="development__image development__more">
        <img
          src="/images/roadmap/linetwo.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage three">
        <div className="light">
          <div>
            <img src="/images/roadmap/build.svg" alt="light avatar" />
            <p>Development Stage Phase III (3rd and 4th Quarter of 2021)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Development Stage Phase III (3rd and 4th Quarter of 2021)</h3>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Developer team assembly</h5>
            </div>
            <p>
              The coming together of the brightest minds in the crypto industry
              to set the ball rolling for Ultainfinity Wealth LaunchPad
              platform.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Roadmap design</h5>
            </div>
            <p>
              Carefully drafted attainable feats for the Ultainfinity Wealth
              Launchpad
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Website design( Ultainfinity Wealth LaunchPad)</h5>
            </div>
            <p>The Ultainfinity Website build commences</p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Website test run I</h5>
            </div>
            <p>
              First functionality test run is conducted on the Ulatinfinity
              Wealth LaunchPad website.
            </p>
          </div>
        </div>
      </div>

      <div className="development__image">
        <img
          src="/images/roadmap/lineone.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage reverse__stage four">
        <div className="light">
          <div>
            <img src="/images/roadmap/cup.svg" alt="light avatar" />
            <p>Getting Started: (1st Quarter 2022)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Getting Started: (1st Quarter 2022)</h3>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>
                Creation of multiple Social media platforms for the Ulatinfinity
                Wealth LaunchPad
              </h5>
            </div>
            <p>
              We pride ourselves as a global project which is not restricted to
              any region of the world. We are everywhere, and the same goes for
              our social media presence. Name any popular social media platform,
              and Ultainfinity Wealth Launch pad is there
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Reaching out to global influencers phase </h5>
            </div>
            <p>
              Reaching out to reputable influencers all over the world
              commences. They are to promote the first Wealth LaunchPad in the
              crypto world.
            </p>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Website test II</h5>
            </div>
            <p>
              Consulting more experts to review the Ultainfinty Wealth LaunchPad
              platform.
            </p>
          </div>
        </div>
      </div>

      <div className="development__image">
        <img
          src="/images/roadmap/linetwo.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage five">
        <div className="light" style={{ marginTop: "-200px" }}>
          <div>
            <img src="/images/roadmap/rocket.svg" alt="light avatar" />
            <p>Getting Started II:(2nd Quarter 2022)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Getting Started II:(2nd Quarter 2022)</h3>

          <div className="details" style={{ marginTop: "70px" }}>
            {/* <div className="sub">
              <div></div>
              <h5>Website test II</h5>
            </div> */}
            <p>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Official Website Launch of the Ultainfinity Wealth LaunchPad
                </li>
                <li>Official Launch of the Whitelist registration </li>
                <li>Official Launch of the PreSale stage 1</li>
                <li>PreSale stage 2</li>
                <li>PreSale stage 3</li>
                <li>PreSale Stage 4</li>
                <li>PreSale Stage 5</li>
              </ul>
            </p>
          </div>
        </div>
      </div>

      <div className="development__image" style={{ marginTop: "-100px" }}>
        <img
          src="/images/roadmap/lineone.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage reverse__stage six">
        <div className="light">
          <div>
            <img src="/images/roadmap/tree.svg" alt="light avatar" />
            <p style={{ fontSize: "20px", lineHeight: "30px" }}>
              Community Growth; social media and Influencer’s marketing (3rd
              Quarter 2022) and Launch of PreMarket exchange{" "}
            </p>
          </div>
        </div>
        <div className="stage__details">
          <h3>
            Community Growth; social media and Influencer’s marketing (3rd
            Quarter 2022) and Launch of PreMarket exchange{" "}
          </h3>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>Official Launch of the Ultainfinity PreMarket exchange</h5>
            </div>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>
                Partnering with Discord, Instagram, Telegram, Facebook, Twitter,
                and LinkedIn Crypto Influencers phase II
              </h5>
            </div>
          </div>
          <div className="details">
            <div className="sub">
              <div></div>
              <h5>
                Appointment of Global Ambassadors for Ultainfinity Wealth
                LaunchPad
              </h5>
            </div>
            <p>
              These are partner representatives of what the Ultainifinity Wealth
              LaunchPad stands for
            </p>
          </div>
        </div>
      </div>

      <div className="development__image">
        <img
          src="/images/roadmap/linetwo.svg"
          alt="line one"
          className="line__image"
        />
      </div>

      <div className="development__stage seven">
        <div className="light">
          <div>
            <img src="/images/roadmap/tree.svg" alt="light avatar" />
            <p>Future Use Cases and Applications (4th Quarter 2022 -)</p>
          </div>
        </div>
        <div className="stage__details">
          <h3>Future Use Cases and Applications (4th Quarter 2022 -)</h3>
          <div className="details">
            <div className="sub">
              <h5>Ultainfinity Wealth LaunchPad Research and Development </h5>
            </div>
            <p>
              <ul style={{ paddingLeft: "20px" }}>
                <li>
                  Further improvements to be made on the entire platform for
                  optimised performance as may be suggested by Ulatainfinity
                  Global Group Research and Development team.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentStages;
