import React from "react";
import { Link } from "react-router-dom";
import CommunityLinks from "../../CommunityLinks";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer__section" id="Subscribe">
      <div className="footer__top" >
        <h6 data-aos="fade-up">Community Channels</h6>
        <h3 data-aos="fade-up">Subscribe</h3>
        <p data-aos="fade-up">
          Subscribe to our Community Channels to get regular updates, our group
          developments and free Airdrops & Bounties of Cryptocurrencies.
        </p>

        <CommunityLinks />

        <div className="footer__links">
          <Link to="/privacy">Privacy</Link>
          <Link to="/terms-conditions">Terms</Link>
          <Link to="/resources">Resources</Link>
          <Link to="/roadmap">Roadmaps</Link>
          <Link to="/whitepaper">Whitepapers</Link>
        </div>
      </div>

      <div className="footer__bottom">
        <p data-aos="fade-up">
          Important to note, trading in cryptocurrencies and in particular
          synthetic assets comes with risks. You can lose all your investment.
          Furthermore, trading in cryptocurrencies generally and trading on
          ULTAINFINITY Exchange UTTA, DIVIT or any assets on our platforms are
          not regulated by any Financial Authority.
        </p>
        <span>© Ultainfinity Wealth Launchpad 2022</span>
      </div>
    </div>
  );
};

export default Footer;
