import React, { useEffect } from "react";
import CommunityLinks from "../../components/CommunityLinks";
import Footer from "../../components/Home/Footer/Footer";
import Header from "../../components/Home/Navbar/Index";
import SocialLinks from "../../components/SocialLinks";
import "./Resources.css";

const Resources = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            <Header />
            <div className="resources__section">
                <div className="resource__hero">
                    <img src="/images/resources/group__2.svg" alt="Group2 Avatar" />
                    <h3>Resources</h3>
                    <p>Check out our frequently updated resources on diverse Ultainfinity projects, it is aimed at ensuring you have a seamless experience on all Ultainfnity platforms</p>
                </div>

                {/* UGG */}
                <div className="resource__section ugg">
                    <div className="resource__logo">
                        <img src="/images/resources/ugg.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>5 easy steps to get your Ultainfinity tokens at PreSale</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <ul>
                                <li>Log in to the Ultainfinity Wealth LaunchPad. You are welcomed by the list of tokens available on display, and you click on your desired tokens for purchase.</li>
                                <li>You are then moved to the whitelist, where you submit an application for presale and pay an initial deposit to validate the opportunity of buying at the lowest price.</li>
                                <li>
                                    Depending on the amount of your initial investment, you will be allowed to participate in a 5-stage PreSale process with price increases from stages 2, 3, 4 and 5. The larger your deposit and larger your
                                    purchase, the more certain you’ll get the lowest possible stage price secured.
                                </li>
                                <li>
                                    The next is the PreSale, where you get to buy as many tokens as possible depending on the level your initial investment affords you to be in. Immediately after the PreSale, you get access to the
                                    Ultainfinity PreMarket exchange.
                                </li>
                                <li>Finally, the Instant CreditLine secured with your additional investment at the whitelist and PreSale will be available to make purchases at the Ultainfinity PreMarket exchange.</li>
                            </ul>
                            <p>Finally, the Instant CreditLine secured with your additional investment at the whitelist and PreSale will be available to make purchases at the Ultainfinity PreMarket exchange.</p>
                            <p>Ultainfinity accepts fiat currencies such as $,£,€ and cryptocurrencies such as SOL, BTC, ETH, BNB, ADA</p>
                        </div>
                    </div>
                </div>

                <div className="resource__section divit">
                    <div className="resource__logo">
                        <img src="/images/resources/divit.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>How do I buy DIVIT tokens?</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>DIVIT token is one of the tokens on the ULTAINFINITY platform, which seeks to entrench the theme of wealth creation by empowering holders to stand a chance to 10× their initial investment.</p>
                            <p>
                                There are three different approaches to getting the DIVIT token on the ULTAINFINITY platform, just like other tokens. Currently, DIVIT token can only be bought after the presale has been launched. The
                                presale, however, consists of five stages with an increase in value on each stage.
                            </p>
                            <p>
                                The second means of getting the DIVIT token is through the <span>ULTAINFINITY Pre-market exchange</span>. Lastly, users can get the token on the <span>ULTAINFINITY Exchange and wallet.</span>
                            </p>
                            <p>
                                Kindly visit the{" "}
                                <a href="https://ultainfinitywealthlaunchpad.com" target="_blank" rel="noreferrer">
                                    ULTAINFINITY
                                </a>{" "}
                                platform to get your token
                            </p>
                        </div>
                    </div>
                </div>

                <div className="resource__section divit">
                    <div className="resource__logo">
                        <img src="/images/resources/utta.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>How do I sell my UTTA tokens?</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>
                                The UTTA token, unlike other tokens in the crypto world, does not support exchange on any form of the exchange platform. You can sell your ULTAINFINITY UTTA token in the following ways: Through the
                                ULTAINFINITY Pre-market exchange. You can use the pre-market Avenue to sell your Utta tokens on the platform.
                            </p>
                            <p>Through the ULTAINFINITY exchange and wallet. This is the exchange process supported by the ULTAINFINITY platform, which enables both buying and selling on the platform.</p>
                        </div>
                    </div>
                </div>

                <div className="resource__section instant">
                    <div className="resource__logo">
                        <img src="/images/resources/creditline.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>Why you need the Ultainfinity Instant CreditLine</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>The fantastic benefits of investing high in Ultainfinity tokens, in a few weeks Think credit score, but better. </p>{" "}
                            <p>
                                The outsized benefits of a high credit score are well known worldwide. But this certainly beats it. Simply investing high in any ULTAINFINITY tokens allows you to have high levels on the Ultainfinity INSTANT
                                CREDITLINE. Let me explain.{" "}
                            </p>{" "}
                            <p>
                                Getting a high-level INSTANT CREDITLINE is simply getting a VIP ticket to enjoy a highly unique product by DASA. Powered by Ultainfinity, the product initially allows registered users to access Instant
                                CreditLine of $10,000 to $250,000.{" "}
                            </p>{" "}
                            <p>
                                The process is super straightforward. An Instant CreditLine is obtained by the simplest of applications, with no credit checks, no personal guarantee, and thus no threat of personal bankruptcy, no requirement
                                to bring in property collateral. These undoubtedly prove that Ultainfinity can be a viable pathway for many people to ultimately have daily multiples of a million at their disposal to trade different
                                Ultainfinity cryptocurrencies.
                            </p>{" "}
                            <p>But how?</p>{" "}
                            <p>
                                You can obtain from $10,000 to $250,000 initially, and after a good repayment track record, your limit automatically increases up to $1M. Imagine having at your disposal daily $1M to trade inUltainfinity
                                cryptocurrencies just by having high investments.{" "}
                            </p>{" "}
                            <p>
                                Imagine being able to buy at lower prices to the tune of $250,000 through the CREDITLINE and now then imagine selling high when the token increases exponentially in value. These benefits outlined have not
                                even scratched the surface as the token potential goes as far as making 99,900×. Indeed, investing high and early can be likened to getting on a wealth accelerator that would catapult you to your dreams
                                faster than you envisaged.
                            </p>
                            <p>
                                Having a high Instant CreditLine allows you to multiply your gains by simply buying low, getting credit low, and selling for a higher price in the Ultainfinity PreMarket Exchange. The power to earn big lies
                                with you; invest high today and profit massively.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="resource__section one__step">
                    <div className="resource__logo">
                        <img src="/images/resources/os__logo.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>Get familiar with the Ultainfinity OneStep ID, OneStep ContactBook</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <h6>OneStep: Ultainfinity Winning solution to Marketing</h6>
                            <p>
                                More often than not, users of online platforms get frustrated by series of pop ups requiring them to fill in their emails and what not before they can enjoy their time on the platform. From a company point of
                                view, no bussiness would survive without users or customers who patronize or visit the platform regularly.As such, platforms do everything possible to ensure they have a reach of more users and to get hold of
                                visitors of their platform by way of emails. However this pop ups irritate users as it ultimately tells on user experience of platforms with such. This and many more necessitated the creation of an all
                                encompassing solution by Mr M Michael for Ultainfinity platforms and its affiliates within the entire ecosystem of Ultainfinity Global Group.
                            </p>{" "}
                            <h6>What is OneStep?</h6>
                            <p>
                                OneStep as the name implies is a solution to multiple unnecessary procedures that can be accomplished in just a single step! This solution helps users to login without the use of emails or the traditionally
                                frustrating signup systems. It works with a simple OneStep ID which shares some characteristic similarities with the Apple ID with more benefits and perks that come with it unlike the Apple ID. The OneStep
                                system can be sub categorized into three working parts:
                            </p>{" "}
                            <ul>
                                <li>The OneStep ID</li>
                                <li>OneStep ContactBook</li>
                                <li>OneStep ContactCash</li>
                            </ul>
                            <h6>What is OneStep ID?</h6>
                            <p>
                                The OneStep ID as earlier stated can be likened to Apple ID, however, with the OneStep ID,all you need is your mobile phone and messenger. The uniqueness of this ID lies in its strong inbuilt security
                                password system. The icing on the cake is the fact that you only have to login once. With user permission, you get to be a part of Ultainfinity Global Group, a worldwide ecosystem comprising, among many
                                others; FinTech, cryptocurrencies, marketplace, messenger without further checks,identification and confirmation.
                            </p>{" "}
                            <h6>OneStep ContactBook</h6>
                            <p>
                                Just like other ecosystems, the OneStep contact book is geared towards endemic and viral expansion of the community to like minds. Ever wanted to share a new opportunity to multiple friends, but the manual
                                approach to sending to one after the other discouraged you? The OneStep ContactBook allows you to easily refer contacts universally to the ecosystem you belong to without having to do it manually. You simply
                                have to click contacts you intend to share with and just like that they get the needed information. Not only that, by just by referring contacts, you would win while the company wins. This feature is inherent
                                in the ContactCash feature of the OneStep project.
                            </p>
                            <h6>Why ContactCash is You win, I win</h6>
                            <p>
                                The era of needless spamming has gone. Just like we are in the age of unprecedented wealth creation and transfer piloted by ULTAINFINITY, the community will grow through organic means without disrupting
                                excellent user experience. By referring contacts to the ULTAINFINITY platform you get incentives per the number of like minded individuals you bring to the platform. The process is so transparent that you are
                                aware of those who takes action or not. This option of the OneStep is however optional. <br /> By way of clicking and referring, you can click your way to thousands of dollars. The platform gets its
                                interested users while you get remunerated for doing so. You win, I win.
                            </p>{" "}
                            <a href="_">Link to the ULTAINFINITY OneStep platform</a>
                        </div>
                    </div>
                </div>

                <div className="resource__section instant">
                    <div className="resource__logo">
                        <img src="/images/resources/ugg__one.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>Community</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>Check out our community on....</p>
                            {/* <SocialLinks /> */}
                            <CommunityLinks />
                        </div>
                    </div>
                </div>

                <div className="resource__section instant">
                    <div className="resource__logo">
                        <img src="/images/resources/ultainfinity.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>ULTAINFINITY- Crypto Wealth creation platform adopts Solana Blockchain</h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>
                                Ultainfinity project has resolved to adopt the Solana blockchain for its crypto project at launch. The Ultainfinity tokens on this blockchain are the UTTA and DIVIT tokens. The project is a worldwide
                                encompassing wealth creation and transfer initiative which seeks to pioneer the largest wealth transfer in the crypto industry.{" "}
                            </p>{" "}
                            <p>
                                The adoption of the Solana blockchain was necessitated after a series of research to understand which blockchain would serve the purpose of the project best. The Solana blockchain is unique in its POH
                                feature( Proof of History) time stamp system which helps to effect transactions by working as an internal system clock. In addition to, the Solana blockchain also operates a proof of stake consensus
                                algorithm, which secures the blockchain network.{" "}
                            </p>{" "}
                            <p>
                                {" "}
                                The Ultainfinity project team, after identifying that the Solana blockchain is indeed the fastest blockchain with the ability to process over 50 000 transactions per second (TPS) at peak load and one of the
                                largest growing cryptocurrency projects in the world, it is therefore appropriate that the only system capable of processing the load of transactions to be undertaken by the Ultainfinity project be done on
                                the fastest, safest and automatic blockchain available.{" "}
                            </p>{" "}
                            <p>
                                {" "}
                                The similarities between the Solana blockchain and the Ultainfinity project is in their uniqueness. Ultainfinity brings wealth creation and transfer in the cryptocurrency industry, which can be likened to
                                Solana's incorporation of smart contracts compatible with Non Fungible Tokens( NFT).{" "}
                            </p>{" "}
                            <p>
                                {" "}
                                Ultainfinity project enthusiasts are, however, assured of safe, speedy and hitch-free transactions at launch and after launch. The safety and convenience of doing business is of utmost importance to the
                                Ultainfinity team.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="resource__section slapa__token">
                    <div className="resource__logo">
                        <img src="/images/resources/slapa__logo.svg" alt="" />
                    </div>
                    <div className="resource__steps">
                        <div className="step__head">
                            <h5>SLAPA LP TOKEN: A project you don't want to miss </h5>
                            <i className="bx bx-chevron-down"></i>
                        </div>
                        <div className="step__body">
                            <p>
                                From the stables of the most incredible wealth creation themed group, the Ultainfnity Global Group (UGG) brings yet another unique token to the world of crypto. As the UGG is popular for its wealth creation
                                projects which include crypto coins, tokens and wealth creation tools like the Utta token, Divit token etc., it adds yet another crypto blockbuster, 'Slapa Lp token'. <br /> As the UGG is known for its unique
                                wealth creation crypto projects, the Slapa Lp token is no exception as it stands on a unique model derived from the SLAPA model and systems. The Slapa Lp token is owned by the SLAPA group, a subsidiary of the
                                UGG.
                            </p>{" "}
                            <h6>What is the Slapa Lp token?</h6>
                            <p>
                                Slapa Lp token is a unique and dedicated liquidity provision token from the Ultainfinity stable of native cryptocurrencies, with a mouth-watering daily and short term return and potentially massive total
                                investment returns. <br /> It is a Model- owned-liquidity token that prevents any form of impairment loss. Think stable coins, but better.A derivative of SLAPA model & systems, the Slapa token sits on an
                                innovative, novel DeFi composite model and, by default, a better-designed protocol producer of reserve currencies. By this, the benefits of the SLAPA Lp token are ample, multidimensional and much more than
                                what is enshrined in stable coins.
                            </p>{" "}
                            <h6>Why is Slapa Lp token better than stablecoins?</h6>
                            <p>
                                Aside from the immediate financially guaranteed uplift benefit, an investor can also uniquely unlock the guaranteed uplift by using the purchased Slapa Lp tokens as collateral to borrow and trade on
                                Ultainfinity and Slapa X cryptocurrencies exchanges, coming soon. SLAPA LP Tokens work as an outright purchased digital asset (typically bought at a significant discount). The level of discount between 25%
                                and 75% is determined by the number of days the purchaser is willing to lock up their minted, replacement SLAPA Token. E.g., a purchaser willing to lock for 75 days gets a 75% discount. SLAPA LP Token is, in
                                summary, a utility and liquidity provision tool used to incentivise BTC, ETH, SOL, and leading stablecoin purchasers who invariably give liquidity outright to SLAPA.
                            </p>{" "}
                            <h6>Why Solana Blockchain?</h6>
                            <p>
                                Slapa Lp token is built on arguably the fastest and safest blockchain in the crypto world. With the Proof of Stake( Pos) and the Proof of Consensus protocol present in the Solana blockchain, It is only right
                                to anchor the Slapa Lp token on the safest and fastest blockchain capable of processing 50,000 transactions per minute.
                            </p>{" "}
                            <h6>Enjoy up to 75% discount with Slapa Lp token</h6>
                            <p>
                                Purchasers of SLAPA LP Tokens benefit from the guaranteed minimum uplift provided by DASA as a result of the significant in-built discount of between 25%-75% and the concomitant added value created by
                                possessing the replacement SLAPA Tokens, which has an inherently higher value compared to the discounted SLAPA LP Token which they purchased. In other words, a purchaser instantly turns, for example, a $2500
                                discounted purchase of SLAPA LP Tokens worth $10,000 into SLAPA Tokens portfolio worth $10,000, locked for 75 days before you can liquidate. The minimum uplift difference between $2,500 and $10,000 in SLAPA
                                Tokens is guaranteed by DASA.
                                <br />
                                For every four SLAPA LP Tokens purchased, four SLAPA Token is minted and equally credited to the purchaser, to DASA, to SLAPA Treasury and to SLAPA Wealth Fund, all locked to match the number of selected days
                                of lock in the contract, The discounted SLAPA LP Tokens are designed to put each purchaser in-the-money and the minted SLAPA Tokens are in exchange for SLAPA LP Tokens, not additional token assets in the
                                wallet of the SLAPA LP Token purchaser In, other words, SLAPA Treasury will ultimately own all SLAPA LP Tokens in addition to the additional minted SLAPA Tokens.
                            </p>{" "}
                            <h6>How does SLAPA LP Token work?</h6>
                            <p>
                                SLAPA LP Tokens work as an outright purchased digital asset (typically bought at a significant discount). The level of discount between 25% and 75% is determined by the number of days the purchaser is willing
                                to lock up their minted, replacement SLAPA Token. E.g, a purchaser willing to lock for 75 days gets a 75% discount. SLAPA LP Token is in summary, a utility and liquidity provision tool used to incentivise
                                BTC, ETH, SOL, and leading stablecoin purchasers who invariably give liquidity outright to SLAPA.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default Resources;
