import React from "react";
import "./roadmap__styles.css";
import GroupStages from "../../assets/Roadmaps/group__stages.svg";
import Line from '../../assets/Roadmaps/line.svg'

const Stages = () => {
    return (
        <div className="schedule__stages">
            {/* <div className="release__head">
                <div></div>
                <h5>5 Stages of the Presales</h5>
            </div> */}
            <div className="stages__image">
                <p className="s5">S5</p>
                <p className="s4">S4</p>
                <p className="s3">S3</p>
                <p className="s2">S2</p>
                <p className="s1">S1</p>
                <img src={GroupStages} alt="Group Stages" />
                <img className="line__image" src={Line} alt="line avatar" />
                <p className="s5__price">$0.5814</p>
                <p className="s1__price">$0.0001</p>
            </div> 
        </div>
    );
};

export default Stages;
