export const faqs = [
  {
    title: "What are Synthetics?",
    content: (
      <p>
        Synthetics are simply references to representations of cryptocurrencies,
        crypto assets, company stocks, fiat currencies, commodities etc. <br />
        <br />
        In a nutshell, Synthetics are assets that reflect the value of other
        assets. Trading of Synthetics requires collateral and counterparty
      </p>
    ),
  },
  {
    title: `How do I trade or invest in Synthetic SLAPA Token ?`,
    content: (
      <p>
        Synthetic SLAPA token works (through minting, contract, collaterization
        and trading on SLAPA-X) with the designed objective to track an
        underlying target asset and to provide returns to users based on on
        conditions that relates to the underlying target asset, without the user
        having to hold or trade the underlying target asset directly. Trading of
        Synthetics SLAPA Token requires collaterals.
        <br />
        <br />
        The unique features of Synthetic SLAPA Token means trading can be
        endless and with unlimited liquidity.
      </p>
    ),
  },
  {
    title: `What are the risks involved with Synthetics? `,
    content: (
      <p>
        Well the most popular reason is to make money. <br />
        <br />
        However, to trade Synthetic Assets you need to have collateral and you
        must be prepared to lose all your money, totally.{" "}
      </p>
    ),
  },
  {
    title: `Why is Synthetic SLAPA Token Special?`,
    content: (
      <p>
        Highest Returns possible, within the fastest period possible. Moreover,
        trading can be endless and with unlimited liquidity, thus reducing
        risks. <br />
        <br />
        This is made possible because of the unique construction and design of
        Synthetic SLAPA Token [s$SLAPA] and the trading of it including the
        counterparty, coupled with the very special, collateralised support made
        available through SACA [Synthetic Assets Collateralised Account] powered
        by DASA. <br />
        <br />
        You won’t find available the Special services within SACA and from DASA,
        anywhere else in the world{" "}
      </p>
    ),
  },
  {
    title: `Can I buy Synthetic SLAPA Token without SACA or Leverage?`,
    content: (
      <p>
        Yes. <br />
        <br />
        You can directly buy Synthetic SLAPA Token from the exchange SLAPA-X.
        You can always trade without a leverage, without a SACA leveraged
        position and without borrowing whatsoever from DASA.
      </p>
    ),
  },
  {
    title: `Why should I trade Synthetic SLAPA Token and other Synthetics from Ultainfinity?`,
    content: (
      <p>
        The possibilities for making significant sums of money is infinite, not
        least, because Ultainfinity has arranged the availability of SACA
        [Synthetic Assets Collateralised Account] to its registered users to
        obtain from DASA upto 100x leverage on the sum of amount that users pay
        into their SACA for use to trade its Synthetics. Terms and fees apply.
        <br />
        <br />
        <strong>An example of High Return made possible by SACA</strong> <br />
        <br />
        It means, using just a 20x leverage multiple; if you pay into your SACA
        $150,000 worth of SLAPA or UTTA cryptocurrency then you may get to trade
        with $3M immediately and thereby have the possibility to turn your
        original $150,000 collateral into $Millions within 24 Hours or few days
        if you engage in multiple leveraged trades. <br />
        <br />
        There’s more, you can use the proceeds of your High Return from the
        initial trade for series of further leveraged trading, multiple times.{" "}
        <br />
        <br /> <strong>Imagine the possibilities</strong> <br />
        <br />
        Without the unique SACA, powered by DASA none of the above High Returns
        would have, ever been possible.
      </p>
    ),
  },
  {
    title: `What is DASA?`,
    content: (
      <p>
        DASA is an acronym for Divits Assets Secured Accounts. It is ultimately
        operated and supplied by the eponymous DASA Lending (a.k.a.DASA). It is
        a bespoke, flexible, innovative, wealth accelerator and unique product.{" "}
        <br />
        <br />
        DASA provides upto $100M in lending, at generous multiples using the
        wealth assets purchased through Ultainfinity. DASA loans and the
        competitive daily interests are rolled-up and the total sum owed is
        regularly reviewed. Collaterals for DASA Lending are primarily
        Ultainfinity Assets including Ultainfinity Cryptocurrencies and other
        leading Cryptocurrencies such as BTC, ETH, SOL etc.
      </p>
    ),
  },
  {
    title: `What is Gross Asset Share?`,
    content: (
      <p>
        Typically, there’s a requirement for higher value of assets to be used
        as collateral in SACA and other necessities including conditions that
        require further costs. However, Ultainfinity has facilitated the
        supplementation by DASA of the whole collateral in lieu of a share of
        the gross assets that arises from the leverage provided by DASA
      </p>
    ),
  },
  {
    title: `What does SLAPA Group comprise?`,
    content: (
      <p>
        <br />
        <br />
        SLAPA, implementers and executors of S L A P A Model &amp; Systems
        through various businesses and platforms.SLAPA-X, the dedicated global
        exchange platform for Synthetic Assets trading, investment, products and
        services. It comes, built-in with the powerful leveraged wealth creation
        tool of SACA by DASA.
        <br />
        <br />
        SLAPA TOKEN, a &nbsp;native utility, revenue-generating and rewards
        cryptocurrency of SLAPA and its global ecosystems including affiliates.
        <br />
        <br />
        SYNTHETIC SLAPA TOKEN, the synthetic asset for the underlying SLAPA
        Token.
      </p>
    ),
  },
  {
    title: `What is the value of Synthetic SLAPA token and from where is the value derived? `,
    content: (
      <p>
        The value is manifold, to all stakeholders including investors, users
        who become uniquely wealthy, fast, and the broader ecosystems of both
        SLAPA Group and Ultainfinity Group.
        <br />
        <br />
        Firstly, Synthetic SLAPA utilises the cauldrons of SLAPA-X to maximize
        performance for investors, especially through its unique SACA by DASA
        product and its rapid wealth creation effects on users, almost
        instantaneously.
        <br />
        <br />
        This therefore, is an immeasurable value and outstanding performance.
        <br />
        <br />
        Secondly, through its collateralised minting protocol, it invariably
        creates inestimable value for its Longterm stakeholders, from whence
        further value is derived as long term holders of the token
        <br />
        <br />
        Thirdly, SLAPA is enriched by synthetic assets and the trades thereof;
        and thus it becomes an ever-growing, formidable and capable liquidity
        provider for its broader ecosystem and those of its affiliates. This
        utility helps to reduce various costs and to ultimately eradicate all
        incentivised liquidity programs. <br />
        <br />
        Fourthly, it derives its value from its primary and secondary utilities,
        not least those aforementioned. It’s utility as a tool and stratagem for
        risk reduction by users, LP’s,and other stakeholders cannot be ignored
        nor impugned
      </p>
    ),
  },
  {
    title: `What is Synthetic $SLAPA, and what does it accomplish?`,
    content: (
      <p>
        Synthetic SLAPA Token is a synthetic asset for the underlying eponymous
        asset SLAPA, which is founded on the core functional strands of
        Stability, Liquidity, Asset, Price Appreciation; derived from S L A P A
        Model &amp; Systems (Simply, SLAPA Model), a blockchain project with
        manifold benefits, global impact and multitudinous use cases.
        <br />
        <br />
        It accomplishes various objectives, not least, the creation of wealth,
        at pace.
        <br />
        <br />
        Important to note, trading in cryptocurrencies and in particular
        synthetic assets comes with risks. You can lose all your investment .
        Furthermore, trading in cryptocurrencies generally and trading on
        SLAPA-X and synthetic SLAPA token are not regulated by any Financial
        Authority.
        <p>
          The value is manifold, to all stakeholders including investors, users
          who become uniquely wealthy, fast, and the broader ecosystems of both
          SLAPA Group and Ultainfinity Group.
          <br />
          <br />
          Firstly, Synthetic SLAPA utilises the cauldrons of SLAPA-X to maximize
          performance for investors, especially through its unique SACA by DASA
          product and its rapid wealth creation effects on users, almost
          instantaneously.
          <br />
          <br />
          This therefore, is an immeasurable value and outstanding performance.
          <br />
          <br />
          Secondly, through its collateralised minting protocol, it invariably
          creates inestimable value for its Longterm stakeholders, from whence
          further value is derived as long term holders of the token
          <br />
          <br />
          Thirdly, SLAPA is enriched by synthetic assets and the trades thereof;
          and thus it becomes an ever-growing, formidable and capable liquidity
          provider for its broader ecosystem and those of its affiliates. This
          utility helps to reduce various costs and to ultimately eradicate all
          incentivised liquidity programs. <br />
          <br />
          Fourthly, it derives its value from its primary and secondary
          utilities, not least those aforementioned. It’s utility as a tool and
          stratagem for risk reduction by users, LP’s,and other stakeholders
          cannot be ignored nor impugned
        </p>
      </p>
    ),
  },
  {
    title: `What drives the price increases of $DIVIT Token, $SLAPA Token and $UTTA Token?`,
    content: (
      <p>
        Quite simply; the solid SLAPA Model &amp; Systems, the ULTAINFINITY
        tokenomics and inexorably thriving ecosystem. All three of our
        cryptocurrencies will continue to rapidly increase in price and value
        due to their multiple use cases and expanding utility across the global
        network of ultainfinity and its affiliated platforms, products, services
        etc.
      </p>
    ),
  },
  {
    title: `What is UCA Super G 1500%+ ?`,
    content: (
      <p>
        UCA Super G is Ultainfinity Cryptocurrencies Assets Super Growth; a
        unique wealth product designed to provide a minimum return of 1500%.
        Only available to Midas Millionaire members and all Connoisseur series
        members.
      </p>
    ),
  },
  {
    title: `What is SLAPA-X ?`,
    content: (
      <p>
        SLAPA-X is a dedicated Global Exchange for Synthetic Assets, with SACA
        by DASA built-in.&nbsp; SACA is Synthetic Assets Collateralised Account.
        <br />‍<br />
        SLAPA-X is Powered by Ultainfinity. Inspired by SLAPA Model &amp;
        Systems. Supported by DASA Lending.
        <br />
      </p>
    ),
  },
];
