import React from "react";
import "./Exchange.css";

const Exchange = () => {
  return (
    <div className="exchange__section" id="exchange" >
      <div className="section__head">
        <h3>EXCHANGE</h3>
      </div>
      <img src="/images/exchange__abs.svg" alt="Exchange avatar" className="exchange__abs" />
      <div className="separate">
        <img src="/images/exchange.svg" alt="exchange avatar" data-aos="fade-up" />
        <div data-aos="fade-up">
          <h5>Synthetic Assets</h5>
          <p>
            SLAPA Synthetic Asset is a prime financial innovation with speedy
            returns. Furthermore, it is limited in scope and wealth
            possibilities only by your imagination. ‍<br />
            <br /> Indeed, there are more Synthetic Assets to come from SLAPA
            and ULTAINFINITY, all powered by SLAPA Model & Systems, such as the
            upcoming Synthetic $UTTA, with 5x or 10x or 20x Returns within 24
            Hours
          </p>
        </div>
      </div>
      <div className="description">
        <div>
          <h5>Ultainfinity Synthetic Assets Platform</h5>
          <p>
            Ultainfinity Exchange for Synthetics Assets & Settlements: SLAPA-X,
            shall provide an accelerated, accessible and accommodative
            wealth-making experience to registered users. It will enable users
            to easily benefit from DASA Instant CreditLine and Collateral
            services and to explore the powerful capabilities of synthetic
            assets everyday, and without the prohibitive requirements of
            technical expertise. <br /><br /> SLAPA Model & Systems provides ULTAINFINITY and
            its users with a rich and robust blockchain infrastructure. Already,
            it has spurred the creation of infinitely powerful synthetic assets,
            starting with Synthetic SLAPA; bringing with it, endless
            possibilities.
          </p>
        </div>
        <img src="/images/platform.svg" alt="platform avatar" />
      </div>
    </div>
  );
};

export default Exchange;
