import { useEffect } from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom"
import AOS from "aos"
import './App.css';
import "aos/dist/aos.css"
import Home from "./pages/Home/Home";
import Privacy from "./pages/privacy"
import Whitepaper from "./pages/Whitepaper/Whitepaper"
import Resources from "./pages/resources/Resources"
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions"
import Roadmap from "./pages/Roadmap"


function App() {
  useEffect(() => {
    AOS.init({duration: 2500})
  }, [])
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/whitepaper" element={<Whitepaper />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/terms-conditions" element={<TermsAndConditions />} />
          <Route path="/roadmap" element={<Roadmap />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
