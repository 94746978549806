import React from "react";
import "./Features.css";

const Features = () => {
  return (
    <div className="features__section" id="Features" >
      <div className="section__head">
        <h3>FEATURES</h3>
      </div>
      <img
        src="/images/features__abs.svg"
        alt="features__abs"
        className="features__abs"
        data-aos="fade-up"
      />
      <div className="separate" data-aos="fade-up">
        <img src="/images/features__img.svg" alt="features__img" />
        <div>
          <h5>Essence of Synthetic $SLAPA</h5>
          <h6>Wealth creation, at pace. Enter, Synthetic SLAPA Token.</h6>
          <p>
            The Synthetic SLAPA Token (s$SLAPA) project combined with SLAPA-X
            and powered by DASA, provides a unique solution to problems of
            creating wealth and to do so with speed. This is possible because
            the combination promises to be a major wealth factory and propeller
            through SLAPA-X, a soon to be available powerful primitive platform
            for daily trading, with limited guarantee, and with 3x or 6x Returns
            within 24 Hours, Daily. <br />
            <br /> The guarantee in Synthetic$SLAPA offering, (special
            conditions apply) makes it easy to foresee “Daily and most Regular
            Traders” being able to quickly build significant wealth portfolios
            from its offerings of 3x or 6x Returns within 24 Hours, These
            Returns can then form the basis for subsequent borrowings, using
            collateralised assets within SLAPA-X. Ultainfinity and SLAPA-X are
            introducers to the ultimate backstop and liquidity provider, DASA.
          </p>
        </div>
      </div>
      <img src="/images/lines.svg" alt="line avatar" className="lines" />
      <div className="description">
        <div data-aos="fade-up">
          <h5>Core Features</h5>
          <p >
            Based on SLAPA Model & Systems and its objectives thereof, s$SLAPA
            trades against the smart contracts which reflects $UTTA. $SLAPA
            Tokens, Going forward, other leading liquid cryptocurrencies will
            added and traded, such SOL,BNB, BTC, ETH, etc. <br /><br /> Synthetic SLAPA Token
            offers the Highest Returns possible, within the fastest period
            possible. It is uniquely constructed and designed with elasticity
            and flexibility, and built for Onchain and Offchain. Furthermore,
            trading can be endless and with unlimited liquidity, thus reducing
            risks. s$SLAPA are traded on SLAPA-X, an Exchange & Settlement
            platform for Synthetic Assets.
          </p>
        </div>
        <img src="/images/graph.svg" alt="graph avatar" data-aos="fade-up" />
        <img src="/images/features__sol.svg" alt="images__sol" className="features__sol" data-aos="fade-up" />
        <img src="/images/built__sol.svg" alt="built__sol" className="built__sol" data-aos="fade-up" />
      </div>
    </div>
  );
};

export default Features;
