import React, {useEffect} from 'react'
import Header from '../components/Home/Navbar/Index'
import Hero from '../components/RoadmapComponents/Hero'
import Schedule from '../components/RoadmapComponents/Schedule'
import DevelopmentStages from '../components/RoadmapComponents/DevelopmentStages'
import Stages from '../components/RoadmapComponents/Stages'
import RoadmapComponent from '../components/RoadmapComponents/Roadmap'
import Footer from '../components/Home/Footer/Footer'

const Roadmap = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
        <Header />
        <Hero />
        <Schedule />
        <DevelopmentStages />
        <Stages />
        <RoadmapComponent />
        <Footer />
    </div>
  )
}

export default Roadmap