import React, {useEffect} from 'react'
import Exchange from '../../components/Home/Exchange/Exchange'
import FAQS from '../../components/Home/FAQS/FAQS'
import Features from '../../components/Home/Features/Features'
import Footer from '../../components/Home/Footer/Footer'
import Hero from '../../components/Home/Hero/Hero'
import HowItWorks from '../../components/Home/HowItWorks/HowItWorks'
import Navbar from '../../components/Home/Navbar/Index'
import Overview from '../../components/Home/Overview/Overview'
import WealthPass from '../../components/Home/WealthPass/WealthPass'
import "./Home.css"

const Home = () => {
  useEffect(() => {window.scrollTo(0, 0)}, [])
  return (
    <div className="home__section">
        <Navbar />
        <Hero />
        <Overview />
        <Exchange />
        <Features />
        <HowItWorks />
        <WealthPass />
        <FAQS />
        <Footer />
    </div>
  )
}

export default Home