import React, { useState } from "react";
import { faqs } from "../../../assets/data/faqs";
import "./FAQS.css";

const FAQS = () => {
  const [currentIdx, setCurrentIdx] = useState(null);

  const handleSetCurrentIdx = (idx) => {
    setCurrentIdx(idx);
  };

  return (
    <div className="faqs__section" id="FAQs">
      <div className="section__head">
        <h3>FAQ</h3>
      </div>
      <div className="faqs" data-aos="fade-up">
        {faqs.map(({ title, content }, idx) => (
          <div className="faq" onClick={() => handleSetCurrentIdx(idx)}>
            <div className="faq__head">
              <div>
                <span>{"0" + (idx + 1)}</span>
                <h5>{title}</h5>
              </div>
              <div className="icon">
                <i className="bx bx-chevron-right"></i>
              </div>
            </div>
            {currentIdx === idx && <div className="faq__body">{content}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQS;
