import React from "react";
import "./roadmap__styles.css";
import TopLine from "../../assets/Roadmaps/top__line.svg";
import BottomLine from "../../assets/Roadmaps/bottom__line.svg";
import StraightLine from "../../assets/Roadmaps/straight__line.svg";

const Roadmap = () => {
    return (
        <div className="roadmap__roadmap">
            <div className="release__head">
                <div></div>
                <h5>Roadmap</h5>
            </div>
            <div className="roadmaps">
                <h3>Q3/22</h3>
                <div className="roadmap__cards">
                    <img src={TopLine} className="top__line" alt="Topline" />
                    <img src={BottomLine} className="bottom__line" alt="BottomLine" />
                    <img src={StraightLine} className="straight__line" alt="StraightLine" />
                    <div className="roadmap__card may">
                        <h5>May</h5>
                        <ul>
                            <li>Global PreSales</li>
                            <li>ULTAINFINITY TV</li>
                            <li>UTTA TV CHANNELS</li>
                            <li>ULTAINFINITY G-MET</li>
                            <li>OneStep</li>
                            <li>Instant CreditLine</li>
                        </ul>
                    </div>
                    <div className="roadmap__card june">
                        <h5>June</h5>
                        <ul>
                            <li>PreMarket Exchange</li>
                            <br />
                            <li>SLAPA Series of Tokens & assets</li>
                            <li>SACA By DASA</li>
                            <li>SLAPA Model & Systems Papers</li>
                        </ul>
                    </div>
                    <div className="roadmap__card july">
                        <h5>July</h5>
                        <ul>
                            <li>ULTAINFINITY WEALTH</li>
                            <br />
                            <li>ULTAINFINITY EXCHANGE</li>
                        </ul>
                    </div>
                    <div className="roadmap__card august">
                        <h5>August</h5>
                        <ul>
                            <li>PreMarket Exchange</li>
                            <li>SLAPA Series of Tokens & assets</li>
                            <li>SACA By DASA</li>
                            <li>SLAPA Model & Systems Papers</li>
                        </ul>
                    </div>
                    <div className="roadmap__card september">
                        <h5>September</h5>
                        <ul>
                            <li>ULTAINFINITY WEALTH</li>
                            <br />
                            <li>ULTAINFINITY EXCHANGE</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roadmap;
