import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import "./Navbar.css";

const Navbar = () => {
  const [backgroundColor, setBackgroundColor] = useState("");
  const [visible, setVisible] = useState(false);

  const handleChangeBackgroundColor = () => {
    if (window.scrollY > 60) {
      setBackgroundColor("#12012a");
    } else {
      setBackgroundColor("");
    }
  };

  const handleShowVisible = () => {
    setVisible(true);
  };
  const handleHideVisible = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleChangeBackgroundColor);
  }, []);

  return (
    <nav style={{ backgroundColor }}>
      <div className="navbar__lg">
        <div className="nav__brand">
          <Link to="/">
            <img src="/images/Logo.svg" alt="Logo avatar" />
          </Link>
          <i className="bx bx-menu" onClick={handleShowVisible}></i>
        </div>
        <div className="nav__links">
          <ul>
            <li>
              <a href="#overview">Overview</a>
            </li>
            <li>
              <a href="#exchange">Exchange</a>
            </li>
            <li>
              <a href="#Features">Features</a>
            </li>
            <li>
              <a href="#HowItWorks">How It Works</a>
            </li>
            <li>
              <a href="#WealthPass">Wealth Pass</a>
            </li>
            <li>
              <a href="#FAQs">FAQS</a>
            </li>
            <li>
              <a href="#Subscribe">
                <button>Subscribe</button>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <Offcanvas
        direction="top"
        isOpen={visible}
        toggle={handleHideVisible}
        onClosed={handleHideVisible}
      >
        <OffcanvasHeader toggle={handleHideVisible}>
          <div className="nav__brand">
            <Link to="/">
              <img src="/images/Logo.svg" alt="Logo avatar" />
            </Link>
            <i className="bx bx-x" onClick={handleHideVisible}></i>
          </div>
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="nav__links">
            <ul>
              <li onClick={handleHideVisible}>
                <a href="#overview">Overview</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#exchange">Exchange</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#Features">Features</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#HowItWorks">How It Works</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#WealthPass">Wealth Pass</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#FAQs">FAQS</a>
              </li>
              <li onClick={handleHideVisible}>
                <a href="#Subscribe">
                  <button>Subscribe</button>
                </a>
              </li>
            </ul>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </nav>
  );
};

export default Navbar;
