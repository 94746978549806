import React from "react";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="how__itWorks" id="HowItWorks">
      <div className="section__head" data-aos="fade-up">
        <h3>HOW IT WORKS</h3>
      </div>
      <div className="works" >
        <div className="work one" data-aos="fade-up">
          <img src="/images/one.svg" alt="One" />
          <p>
            Synthetic SLAPA utilizes a unique model where traders deposit
            collateral into SACA [Synthetic Assets Collateralised Account] to
            enable peer to contract trading of Synthetic SLAPA Token, limited to
            set terms in multiple hours, typically within every 24 hours,
            against set volatility parameters of UTTA using uniquely constructed
            designs and counterparty. It includes buy and sell contracts related
            to the underlying asset. The supported collaterals are UTTA,and in
            near future SLAPA, cryptocurrency only.
          </p>
        </div>

        <div className="work two" data-aos="fade-up">
          <img src="/images/two.svg" alt="One" />
          <p>
            UTTA Token is deposited in SACA as collateral and Synthetic SLAPA
            Token [s$SLAPA, also known as s$Ts] is minted against it.
            Furthermore, additional UTTA Tokens are credited into the SACA by
            DASA as leverage multiples (if applied for by User)and they become
            collateralized debt positions (CDPs) secured against the SACA and
            it’s eponymous contracts / agreements. SLAPA’s trading platform
            SLAPA-X has plans to accept UTTA Coin, SLAPA Coin and SLAPA Token as
            additional forms of collateral, going forward.
          </p>
        </div>

        <div className="work one" data-aos="fade-up">
          <img src="/images/three.svg" alt="One" />
          <p>
            DASA provides liquidity and leverage capabilities within SACA and
            thus, the smooth trading of Synthetic SLAPA Token. As the lender and
            backstop taking on multiple exposure and risks; it is incentivised
            and rewarded with various receipts of charges, gross share of
            assets, fees, optioned and new UTTA, wherefrom the value of
            Synthetic SLAPA is indeed derived.
          </p>
        </div>

        <div className="work two" data-aos="fade-up">
          <img src="/images/four.svg" alt="One" />
          <p>
            Synthetic SLAPA Tokens will also be traded against a limited
            volatility range of the replicated UTTA, off-chain. Settlements are
            made using DIVIT Tokens which can be swapped and paired with other
            Ultainfinity cryptocurrencies and other leading cryptocurrencies
            such as BTC, ETH, BNB, SOL, ADA on Ultainfinity Exchange &
            Marketplace.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
