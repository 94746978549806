import React from "react";
import "./Overview.css";

const Overview = () => {
  return (
    <div className="overview__section" id="overview">
      <div className="section__head">
        <h3>OVERVIEW</h3>
      </div>
      {/* <img src="/images/Vector.svg" alt="Vector avatar" className="vector__avatar" /> */}
      <div className="separate">
        <img src="/images/overview.svg" alt="overview avatar" data-aos="fade-up" />
        <div data-aos="fade-up">
          <h5>Background</h5>
          <p>
            Let’s start with an overview and introduction to S L A P A Model.
            <br /> <br />
            SLAPA as an Eponymous Asset, founded on the core functional strands
            of Stability, Liquidity, Asset, Price Appreciation; is derived from
            S L A P A Model & Systems (Simply, SLAPA Model), a blockchain
            project with manifold benefits, global impact and multitudinous use
            cases.
          </p>
        </div>
      </div>

      {/* Description */}
      <div className="description">
        <div>
          <h5>Synthetic $SLAPA</h5>
          <p>
            SLAPA as an Asset gave rise to Synthetic SLAPA Token ($SLAPA) a
            blockchain and offchain project. Synthetic $SLAPA can be traded
            downwards or upwards. Take any position on the underlying asset. It
            captures all movements including the superior, solid and stable
            price appreciation that is deliverable from the application of SLAPA
            Model unto Target Asset(s) such as UTTA Token or the Eponymous
            Asset: SLAPA Token.
          </p>
        </div>
        <img src="/images/running.svg" alt="running avatar" />
      </div>
    </div>
  );
};

export default Overview;
