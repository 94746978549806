import React from "react";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero__section" data-aos="fade-up">
        {/* <img src="/images/bg.svg" alt="background svg" /> */}
      <h3>The Possibilities are Infinite with Synthetic $SLAPA</h3>
      <p>Experience 3X or 6X Return, Within 24 Hours, Daily.</p>
      <a href="#overview"><button>
        Learn More <i className="bx bx-down-arrow-alt"></i>
      </button></a>
    </div>
  );
};

export default Hero;
