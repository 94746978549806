import React from "react";

const CommunityLinks = () => {
  return (
    <div className="community__links">
      <div className="step__one">
        <a
          href="https://www.facebook.com/synthetic.slapatoken"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/facebook.svg" alt="facebook" />
        </a>
        <a
          href="https://www.instagram.com/syntheticslapa"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/instagram.svg" alt="instagram" />
        </a>
        <a href="#">
          <img src="/images/snapchat.svg" alt="snapchat" />
        </a>
        <a
          href="https://www.tiktok.com/@synthetic.slapa.token"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/tiktok.svg" alt="tiktok" />
        </a>
        <a
          href="https://twitter.com/syntheticslapa1"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/twitter.svg" alt="twitter" />
        </a>
        <a
          href="https://www.linkedin.com/in/ultainfinitywealthlaunchpad"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/linkedin.svg" alt="linkedin" />
        </a>
        <a
          href="https://www.reddit.com/r/syntheticslapatoken/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/reddit.svg" alt="reddit" />
        </a>
        <a
          href="https://medium.com/@syntheticslapatoken"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/medium.svg" alt="medium" />
        </a>
        <img src="/images/discord.svg" alt="discord" />
        <a
          href="http://www.pinterest.com/syntheticslapatoken"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/pinterest.svg" alt="pinterest" />
        </a>
      </div>
      <div className="step__two">
        <a
          href="https://divittoken.quora.com/"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/quora.svg" alt="quora" />
        </a>
        <a
          href="https://www.youtube.com/channel/UCa6ags_NQ7NLMddnq74wWRg"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/youtube.svg" alt="youtube" />
        </a>
        <a
          href="https://t.me/syntheticslapatoken_channel"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/telegram.svg" alt="telegram" />
        </a>
        <img src="/images/likee.svg" alt="likee" />
        <a
          href="https://vk.com/syntheticslapatoken"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/vk.svg" alt="vk" />
        </a>
        <a href="http://m.me/109020541664806" target="_blank" rel="noreferrer">
          <img src="/images/messenger.svg" alt="messenger" />
        </a>
        <a
          href="https://invite.viber.com/?g2=AQBCVl7zHoathE65IH2cQUoPyu%2F8bk%2FGeyJ0Dil85H%2FB795NJd7t8tZKfn%2Fcvd40"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/viber.svg" alt="viber" />
        </a>
        <img src="/images/line.svg" alt="line" />
        <img src="/images/viber__two.svg" alt="viber__two" />
        <a href="https://signal.org/install" target="_blank" rel="noreferrer">
          <img src="/images/signal.svg" alt="signal" />
        </a>
      </div>
    </div>
  );
};

export default CommunityLinks;
